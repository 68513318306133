<template>
    <router-link :to="{name: 'card', params: {id: id}}" class="rating-item">
        <div class="number">{{ number }}</div>
        <UserAvatar :photo="photo" :gender="gender"/>
        <div class="info">
            <div class="rating-card_title">{{ fullName }}</div>
            <div class="rating-card_subtitle">{{ council_name }}</div>
        </div>
        <button v-if="pickedFilter === 7" class="popup-btn" @click.prevent.stop="$emit('openPopup', id)"></button>
    </router-link>
</template>
<script>
import UserAvatar from "@/components/UserAvatar";

export default {
    name: "RatingItem",
    components: {
        UserAvatar
    },
    props: {
        number: {
            type: Number,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        first_name: {
            type: String,
            required: true
        },
        last_name: {
            type: String,
            required: true
        },
        council_name: {
            type: String,
            required: true
        },
        gender: {
            type: Number,
            required: true
        },
        photo: {
            type: String,
            required: false,
            default: ''
        },
        pickedFilter: {
            type: Number,
            required: false
        },
    },
    computed: {
        fullName: function () {
            return `${this.first_name} ${this.last_name}`;
        }
    }
}
</script>

<style lang="less" scoped>
.ratings-card_add {
    .arrow {
        right: 16px !important;
    }
}
.rating-card_title{
    font: 700 20px/1.4 @secondFontFamily;
}
.rating-card_subtitle{
    font-size: 16px;
    line-height: 1.5;
}
.rating-item {
    background-color: #fff;
    display: flex;
    height: fit-content;
    position: relative;
    padding: 20px 40px;
    margin-bottom: 5px;
    text-decoration: none;
    transition: transform .15s linear;
    color: @blueColor;
    &:hover{
        transform: scale(1.01);
    }
    .number{
        display: flex;
        align-items: center;
        font-size: 32px;
        font-weight: 300;
        line-height: .88;
        letter-spacing: -.22px;
    }
    .avatar{
        margin-left: 40px;
        @media all and (max-width: 650px) {
            margin: 20px 0;
        }
    }
    .info {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        margin-left: 20px;
        @media all and (max-width: 650px) {
            margin-left: 0;
            display: block;
            text-align: center;
        }
    }
    .popup-btn {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        margin-top: -20px;
        right: 40px;
        transition: box-shadow .15s linear;
        border-radius: 15px;
        background: @yellowColor url("./../../../assets/images/ratings-pup-up.svg") center center no-repeat;
        @media all and (max-width: 650px) {
            position: static;
            margin-top: 20px;
        }
        &:hover{
            box-shadow: 0 20px 20px -4px fade(@yellowColor, 35%);
        }
    }
    @media all and (max-width: 650px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
</style>
