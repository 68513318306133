<template>
    <div class="diagram" @click="$emit('close', id)">
        <div class="diagram-wrapper" @click.stop>
            <div class="diagram-close" @click="$emit('close', id)">
                <div class="diagram-close_uno"></div>
            </div>
            <header>Деталізація рейтингу</header>
            <div class="diagram-content">
                <div class="diagram-content-left">
                    <Doughnut :options="chartOptions" :chart-data="chartData"/>
                </div>
                <div class="diagram-content-right">
                    <div class="toptop">
                        <UserAvatar :photo="photo" :gender="gender"/>
                        <div class="ratings-card_info">
                            <div class="rating-card_title">{{ fullName }}</div>
                            <div class="rating-card_subtitle" v-if="councilName">{{ councilName }}</div>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Дiяльнiсть</th>
                                <th>Вiдсотки</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in chartData.datasets[0].data">
                                <td>
                                    <span :style="{background: chartData.datasets[0].backgroundColor[index]}" class="color"></span>
                                    {{chartData.datasets[0].labels[index]}}
                                </td>
                                <td><strong>{{item}}%</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Doughnut from "@/components/Doughnut";
import UserAvatar from "@/components/UserAvatar";

export default {
    name: "RatingModal",
    components: {
        Doughnut,
        UserAvatar
    },
    props: {
        id: {
            type: Number,
            required: true
        },
        gender: {
            type: Number,
            required: true
        },
        firstName: {
            type: String,
            required: true
        },
        lastName: {
            type: String,
            required: true
        },
        ratingDetails: {
            type: Array,
            required: false,
            default: []
        },
        councilName: {
            type: String,
            required: false
        },
        photo: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                showLines: false,
                animation: {
                    animateRotate: false
                },
                tooltips: {
                    enabled: false
                },
                hover: {mode: null},
                legend: {
                    display: false
                },
            },
        }
    },
    created() {
        window.addEventListener('keyup', this.keyboardHandler);
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.keyboardHandler);
    },
    computed: {
        chartData() {
            let dataset = {
                borderWidth: 0,
                labels: ['Медійна діяльність', 'Партійна діяльність', 'Депутатська діяльність'],
                backgroundColor: ['#B601D8', '#F40035', '#19B4F8'],
                data: []
            };
            this.ratingDetails.forEach(rating => dataset.data.push(rating.value));
            return {datasets: [dataset]};
        },
        fullName() {
            return `${this.firstName} ${this.lastName}`;
        }
    },
    methods: {
        keyboardHandler(e) {
            if (e.keyCode === 27) {
                this.$emit('close', this.id);
            }
        }
    }
}
</script>
<style lang="less">
    canvas {
        width: 204px!important;
        height: 204px!important;
    }
</style>
<style scoped lang="less">
    .diagram {
        min-width: 320px;
        position: sticky;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        top: 0;
        height: 100%;
        z-index: 1;
        right: 0;
        bottom: 0;
        background-color: fade(@blueColor, 60%);
        @media all and (max-width: 950px) {
            position: fixed;
            left: 0;
        }
        header{
            border-bottom: 1px solid rgba(0,0,0,.15);
            padding-bottom: 40px;
            font: 600 30px/1.33 @secondFontFamily;
            letter-spacing: 0.375px;
            @media all and (max-width: 678px) {
                text-align: center;
            }
            @media all and (max-width: 470px) {
                font-size: 24px;
                line-height: 1.4;
                padding-bottom: 10px;
                max-height: 100%;
            }
        }

        .toptop {
            display: flex;
            width: 100%;
            .avatar{
                margin-right: 20px;
            }
        }
        table{
            margin-top: 40px;
            thead{
                color: #9da3ae;
                th{
                    font: 600 12px/1.33 @defaultFontFamily;
                }
                th{
                    padding-bottom: 12px;
                    text-align: left;
                    &:last-child{
                        text-align: right;
                    }
                }
            }
            tbody{
                color: @blueColor;
                td:first-child{
                    font-size: 16px;
                    line-height: 1.25;
                    letter-spacing: -.11px;
                    text-align: left;
                    span{
                        display: inline-block;
                        border-radius: 12px;
                        margin-right: 10px;
                        width: 20px;
                        height: 12px;
                    }
                }
                td{
                    padding-bottom: 3px;
                }
                td:last-child{
                    text-align: right;
                    padding-left: 30px;
                    font: 700 18px/1.33 @secondFontFamily;
                }
            }
        }
        .diagram-content {
            display: flex;
            margin-top: 40px;
            @media all and(max-width: 678px) {
                flex-direction: column;
                align-items: center;
            }
            &-right {
                padding-left: 80px;
                flex: 1 1 auto;
                @media all and(max-width: 1100px) {
                    padding-left: 20px;
                }
                @media all and(max-width: 678px) {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: column;
                    padding-left: 0;
                }
            }
            &-left{
                @media all and(max-width: 678px) {
                    max-width: 204px;
                    width: 100%;
                }
            }
        }

        .ratings-card {
            &_info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .rating-card_title{
                    font: 700 20px/1.4 @secondFontFamily;
                    letter-spacing: -.14px;
                }
                .rating-card_subtitle{
                    font-size: 16px;
                    line-height: 1.5;
                }
            }
        }
        .diagram-wrapper {
            padding: 40px 100px;
            display: flex;
            position: relative;
            flex-direction: column;
            width: 90%;
            max-width: 800px;
            background-color: #fff;
            z-index: 3;
            @media all and(max-width: 1300px) {
                padding: 40px;
            }
            @media all and(max-width: 950px) {
                padding: 40px 100px;
            }
            @media all and(max-width: 800px) {
                padding: 40px;
            }
            @media all and (max-width: 470px) {
                width: 100%;
                padding: 40px 20px;
            }
        }
        &-close {
            position: absolute;
            top: 20px;
            right: 20px;
            &:hover {
                background-color: #fff;
            }
        }
        &-close {
            &_uno {
                position: relative;
                height: 15px;
                width: 15px;
                cursor: pointer;
                &:before, &:after{
                    position: absolute;
                    content: '';
                    height: 4px;
                    top: 5px;
                    right: -2px;
                    width: 20px;
                    background-color:#CDD4E0;
                    border-radius: 2px;
                    transition: background-color .15s linear;
                }
                &:before {
                    transform: rotate(-45deg);
                }
                &:after {
                    transform: rotate(45deg);
                }
                &:hover {
                    &:before, &:after{
                        background-color: @yellowColor;
                    }
                }
            }
        }
    }
</style>
