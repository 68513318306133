<template>
    <div class="avatar">
        <img :src="photo" v-if="photo" alt="user photo" loading="lazy">
        <img v-else-if="gender === 0" src="@/assets/images/icon-male.svg" alt="male default image">
        <img v-else src="@/assets/images/icon-female.svg" alt="female default image">
    </div>
</template>

<script>
export default {
    name: "UserAvatar",
    props: {
        gender: {
            type: Number,
            required: true
        },
        photo: {
            type: String,
            required: false
        }
    },
}
</script>

<style scoped lang="less">
.avatar {
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    overflow: hidden;
    border-radius: 50%;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
</style>
