<template>
    <section class="wrapper">
        <CommonBlock title="Рейтинги" :breadcrumbs="[{id: 1, name: 'Рейтинги'}]" class="rating-common-block">
            <div class="ratings-img">
                <img src="@/assets/images/ratings-stars.svg" alt="back" :class="{visible: currentTab === 1}"/>
                <img src="@/assets/images/top_20.svg" alt="back" :class="{visible: currentTab === 5}"/>
                <img src="@/assets/images/top_50.svg" alt="back" :class="{visible: currentTab === 7}"/>
            </div>
        </CommonBlock>
        <article class="content custom-scroll" :class="{hide: hasSelectedUsers}">
            <div class="content_bg">
                <header>
                    <ul>
                        <li v-for="tab in tabs" :key="tab.id">
                            <TabHeaderItem
                                :id="tab.id"
                                :label="tab.name"
                                :current-id="currentTab"
                                @change="changeTab"
                            />
                        </li>
                    </ul>
                </header>
                <div class="content-top">
                </div>
                <div class="content-body" v-if="currentTab === 7">
                    <RatingItem v-for="(card, index) in cards" :key="card.id"
                        :id="+card.id"
                        :number="index + 1"
                        :first_name="card.first_name"
                        @openPopup="openPopup"
                        :last_name="card.last_name"
                        :gender="+card.gender"
                        :council_name="card.rada_name"
                        :photo="card.photo"
                        :pickedFilter="currentTab"
                    />
                </div>

                <div class="content-body content-body-collapse" v-if="currentTab !== 7">
                    <div class="collapse" :class="{opened: currentCollapse === id}" v-for="id in council_sort" :key="id" v-if="Object.keys(councils[id] || {}).length">
                        <header @click="currentCollapse = currentCollapse === id ? null : id" class="collapse-header">
                            {{councils[id]}}
                            <button>
                                <svg width="32px" height="32px" viewBox="-5 -1 16 34">
                                    <path d="M10.625 17.429c0 0.143-0.071 0.304-0.179 0.411l-8.321 8.321c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-0.893-0.893c-0.107-0.107-0.179-0.25-0.179-0.411 0-0.143 0.071-0.304 0.179-0.411l7.018-7.018-7.018-7.018c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l0.893-0.893c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l8.321 8.321c0.107 0.107 0.179 0.268 0.179 0.411z"></path>
                                </svg>
                            </button>
                        </header>
                        <collapse-transition>
                            <div class="collapse-body" v-if="currentCollapse === id">
                                <RatingItem v-for="(card, index) in ratingsByCouncil[id] || []" :key="card.id"
                                            :id="+card.id"
                                            :number="index + 1"
                                            :first_name="card.first_name"
                                            @openPopup="openPopup"
                                            :last_name="card.last_name"
                                            :gender="+card.gender"
                                            :council_name="card.rada_name"
                                            :photo="card.photo"
                                            :pickedFilter="currentTab"
                                />
                            </div>
                        </collapse-transition>
                    </div>
                </div>
            </div>
            <transition name="diagram-animation">
                <RatingModal
                    v-if="hasSelectedUsers"
                    :gender="+selectedUser.gender"
                    :council-name="selectedUser.rada_name"
                    :first-name="selectedUser.first_name"
                    :last-name="selectedUser.last_name"
                    :photo="selectedUser.photo"
                    :rating-details="ratingDetails"
                    :id="+selectedUser.id"
                    @close="selectedUserId = null"
                />
            </transition>
        </article>
    </section>
</template>

<script>
import CommonBlock from "@/components/CommonBlock";
import UserService from "@/services/UserService";
import TabHeaderItem from "@/components/TabHeaderItem";
import RatingModal from "./components/RatingModal";
import RatingItem from "./components/RatingItem";
import CouncilService from "@/services/CouncilService";

export default {
    name: 'Rating',
    components: {
        CommonBlock,
        RatingItem,
        TabHeaderItem,
        RatingModal
    },
    data() {
        return {
            currentCollapse: null,
            currentTab: 1,
            tabs: [{
                id: 1,
                name: 'Топ-50 <br/> медійно-активних депутатів',
            }, {
                id: 5,
                name: 'Топ-50 <br/> партійно-активних депутатів'
            }, {
                id: 7,
                name: 'Топ-20 <br/> найбільш активних депутатів'
            }],
            cards: [],
            selectedUserId: null,
            ratingDetails: [],
            council_sort: [7, 2, 3, 4, 1, 6, 5],
            councils: [],
            ratingsByCouncil: {}
        }
    },
    async created() {
        this.currentTab = +this.$route.query.type || this.currentTab;
        await this.setCardsById(this.currentTab);
        await this.getUsers();
    },
    methods: {
        async getUsers() {
            this.councils = await CouncilService.getLevel();

            let first_element = this.council_sort[0];
            for (const id of this.council_sort) {
                UserService.getRating(this.currentTab, id).then(resp => {
                    this.ratingsByCouncil[id] = resp;
                    console.log(id);
                    if(+first_element === +id) {
                        this.currentCollapse = +id;
                    }
                }).catch(e => {});
            }
        },
        async openPopup(id) {
            this.selectedUserId = id;
            this.ratingDetails = await UserService.getRatingDetails(id);
        },
        changeTab(id) {
            this.currentTab = +id;
            history.pushState({}, null, `?type=${this.currentTab}`);
            this.getUsers();
            this.currentCollapse = null;
            this.councils = [];
            this.ratingsByCouncil = {};
            this.setCardsById(id);
        },
        async setCardsById(id) {
            if(id === 7) {
                this.cards = await UserService.getRatingActivity();
            }else{
                this.cards = await UserService.getRating(id).catch(() => this.filteredCards = []);
            }
        }
    },
    computed: {
        hasSelectedUsers() {
            return !!Object.keys(this.selectedUser).length;
        },
        selectedUser() {
            return this.cards.find(item => +item.id === this.selectedUserId) || {};
        }
    }
}
</script>

<style lang="less">
.diagram-animation-leave-active, .diagram-animation-enter-active {
    transition: all .2s linear;
    .diagram-wrapper{
        transition: all .2s ease-in;
    }
}
.diagram-animation-enter, .diagram-animation-leave-to {
    opacity: 0;
    .diagram-wrapper{
        transform: translateY(-80%);
    }
}
.rating-common-block{
    .slot{
        min-height: 468px;
    }
}
</style>

<style lang="less" scoped>
.content-body-collapse{
    margin-top: 20px!important;
}
.collapse{
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 5px;
    &.opened{
        .collapse-header{
            background-color: @yellowColor;
            color: #fff;
            button{
                background-color: #fff;
                svg{
                    transform: rotate(-90deg) translateX(0px) translateY(-1px);
                    margin-left: -2px;
                    margin-top: 1px;
                    fill: @blueColor;
                }
            }
        }
    }
    .collapse-header{
        cursor: pointer;
        display: flex;
        padding: 9px 18px 9px 60px;
        border-radius: 10px;
        height: 50px;
        align-items: center;
        justify-content: space-between;
        font: 600 24px/1 @secondFontFamily;
        background-color: #9da3ae;
        transition: color .15s linear, background-color .15s linear;
        @media all and (max-width: 500px) {
            font-size: 16px;
            padding-left: 25px;
        }
        button{
            width: 32px;
            height: 32px;
            background-color: @yellowColor;
            border-radius: 50%;
            transition: background-color .15s linear;
            svg{
                transform: rotate(90deg) translateX(-1px) translateY(-1px);
                transition: transform .15s linear, fill .15s linear;
                fill: #fff;
            }
        }
    }
    .collapse-body{
        .rating-item:not(:last-child){
            border-bottom: 1px solid rgba(0,0,0,.03);
        }
    }
}
.ratings-img{
    position: absolute;
    height: 100%;
    width: 100%;
    img{
        position: absolute;
        opacity: 0;
        transition: opacity .3s linear, visibility .3s linear;
        visibility: hidden;
        bottom: 0;
        width: 100%;
        height: auto;
        &:first-child{
            max-width: 420px;
        }
        &:nth-child(2){
            max-width: 416px;
        }
        &:last-child{
            max-width: 430px;
        }
        &.visible{
            opacity: 1;
            visibility: visible;
        }
    }
}
.content {
    position: relative;
    padding: 0!important;
    &.hide{
        overflow-y: hidden;
    }
    .content_bg{
        padding: 96px 40px 40px 40px;
        @media all and (max-width: 950px) {
            padding: 20px;
        }
        ul{

        }
    }
    header{
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0,0,0,.03);
        ul{
            width: 100%;
            display: flex;
            list-style: none;
            background-color: #fff;
            border-radius: 10px;
            @media all and (max-width: 768px) {
                margin-top: 20px;
            }
            @media all and(max-width: 1000px) {
                display: block;
                li{
                    width: 100% !important;
                }
            }
            li{
                width: 33.3333%;
                button{
                    width: 100%;
                }
            }
        }
    }

    &-body {
        margin-top: 40px;
        @media all and (max-width: 950px) {
            margin-top: 20px;
        }
    }
}
.common-block.deputies{
    ::v-deep{
        .breadcrumb{
            li a{
                color: #ffc900;
            }
            li:last-child:before{
                background: white;
            }
        }
    }
}
.common-block{
    .body{
        @media (max-width: 950px){
            display: block;
            .slot{
                display: none;
            }
        }
    }
    @media (max-width: 950px){
        min-height: 20vh;
    }
}
</style>
